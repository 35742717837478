
import Vue from 'vue';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import utils from '../../util';

export default Vue.extend({
  name: 'AdvertiserView',
  components: {
    AppGlobals: () => import('../global/globals.vue'),
    AdvertiserDetails: () => import('./advertiserDetails.vue'),
    CampaignDetails: () => import('./campaignDetails.vue'),
    ProductDetails: () => import('./productDetails.vue'),
    ToolbarC360: () => import('../components/toolbar/toolbarC360.vue'),
    FooterC360: () => import('../global/footerC360.vue'),
    ProjectVersion: () => import('../global/projectVersion.vue'),
    Status: () => import('../global/status.vue'),
    C360Provider,
  },
  data: (): {
    selectedSearch: string;
    refreshToken: string;
    config: object;
  } => ({
    selectedSearch: 'Advertiser',
    refreshToken: '',
    config: {
      whatIsNewDialog: {
        page: '',
      },
    },
  }),
  mounted(): void {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
    setTimeout(() => {
      this.config = {
        whatIsNewDialog: {
          page: `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`,
        },
      };
    }, 500);
    Vue.nextTick(() => {
      try {
        document.title = 'Advertiser View -  Analytics Dashboard';
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error(exp);
      }
    });
  },
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    isLoading(): boolean {
      if (this.$store.state.customer.adBlockDetected) {
        return false;
      }
      return (
        this.$store.state.customer.waitingForAuth ||
        this.$store.state.customer.loadingAdPerformance ||
        this.$store.state.advertiser?.isAdvertiserInfoLoading
      );
    },
    isAdvertiser(): boolean {
      return this.selectedSearch === 'Advertiser';
    },
    isCampaign(): boolean {
      return this.selectedSearch === 'Campaign';
    },
    isProduct(): boolean {
      return this.selectedSearch === 'Product';
    },
  },
  methods: {
    updateAdvertiserView(value: string): void {
      this.selectedSearch = value;
    },
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
});
